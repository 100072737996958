import React, { Component } from "react";
import { androidBase64, iosBase64 } from "../../../assets/download/base-64";
import { LINKS } from "../../../library/links";
import { Link } from "gatsby";
import { KIFFGO } from "../../../library/kiffgo";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import styles from "./Footer.styles";
import { css } from "aphrodite";
import STYLES from "../../../styles/shared";
import { SERVICES } from "../../../library/services";
import Utils from "../../../library/utils";
import FooterReviews from "../FooterReviews/FooterReviews";

class Footer extends Component {
  handlePlayStoreClick() {
    ReactGA.event({
      category: "Browsing",
      action: "Download button clicked - Footer",
      label: "Footer Play Store",
    });
    ReactPixel.track("Lead", {
      content_name: "Footer Play Store",
      content_category: "Download Clicked",
    });
  }

  handleAppleStoreClick() {
    ReactGA.event({
      category: "Browsing",
      action: "Download button clicked - Footer",
      label: "Footer App Store",
    });
    ReactPixel.track("Lead", {
      content_name: "Footer App Store",
      content_category: "Download Clicked",
    });
  }

  handlePhoneNumberClick() {
    ReactGA.event({
      category: "Browsing",
      action: "Phone number clicked - Footer",
      label: "Footer",
    });
  }

  componentDidMount() {
    /* window.addEventListener("load", function () {
      for (
        let i = 0;
        i < document.querySelectorAll(".MuiButton-containedPrimary").length;
        i++
      ) {
        document.querySelectorAll(".MuiButton-containedPrimary")[
          i
        ].onclick = function () {
          if (window.location.pathname.indexOf("/book") != -1) {
            window.gtag("event", "conversion", {
              send_to: "AW-832039840/SHwVCOWNmIEBEKDX34wD",
            });
          }
        };
      }
      if (window.location.pathname.indexOf("successful-booking") != -1) {
        window.gtag("event", "conversion", {
          send_to: "AW-832039840/MzUICP6XldYBEKDX34wD",
          transaction_id: "",
        });
      }
    }); */
  }

  render() {
    return (
      <footer>
        <FooterReviews />
        <div className={css(styles.root)}>
          <div className={css(styles.content)}>
            <div className={css(styles.footerSectionsRoot)}>
              <div
                className={css(styles.footerSection, styles.footerSectionone)}
              >
                <ul className={css(styles.ul)}>
                  <li className={css(styles.li, styles.socialLimain)}>
                    <Link to={"/"}>
                      <img
                        alt={"Man and van app"}
                        className={css(styles.footerIconLogo)}
                        draggable={false}
                        src={require("../../../assets/images/jjd-logo.png")}
                      />
                    </Link>
                    <li className={css(styles.li, styles.socialLimobile)}>
                      <a
                        className={css(styles.socialLink)}
                        target={"_blank"}
                        href={LINKS.Facebook}
                      >
                        <img
                          alt={"JJD Facebook"}
                          className={css(styles.socialLinkImg)}
                          src={require("../../../assets/social/facebook.svg")}
                        />
                      </a>
                      <a
                        className={css(styles.socialLink)}
                        target={"_blank"}
                        href={LINKS.Youtube}
                      >
                        <img
                          alt={"JJD Youtube"}
                          className={css(styles.socialLinkImg)}
                          src={require("../../../assets/social/youtube.png")}
                        />
                      </a>
                      <a
                        className={css(styles.socialLink)}
                        target={"_blank"}
                        href={LINKS.Instagram}
                      >
                        <img
                          alt={"JJD Instagram"}
                          className={css(styles.socialLinkImg)}
                          style={{ maxWidth: 28 }}
                          src={require("../../../assets/social/instagram.svg")}
                        />
                      </a>
                    </li>
                  </li>
                </ul>
              </div>

              <div
                className={css(styles.footerSection, styles.footerSectiontwo)}
              >
                <h3 className={css(styles.header)}>Company</h3>
                <ul className={css(styles.ul)}>
                  <li className={css(styles.li)}>
                    <Link className={css(styles.link)} to={"/book"}>
                      Book online
                    </Link>
                  </li>
                  <li className={css(styles.li)}>
                    <Link className={css(styles.link)} to={"/about"}>
                      About us
                    </Link>
                  </li>
                  <li className={css(styles.li)}>
                    <Link className={css(styles.link)} to={"/reviews"}>
                      Reviews
                    </Link>
                  </li>
                </ul>
              </div>

              <div
                className={css(styles.footerSection, styles.footerSectionthree)}
              >
                <h3 className={css(styles.header)}>Services</h3>
                <ul className={css(styles.ul)}>
                  {SERVICES.map((service, index) => (
                    <li
                      key={index}
                      className={css(styles.li, styles.serviceslink)}
                    >
                      <Link className={css(styles.link)} to={service.url}>
                        {service.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>

              <div
                className={css(styles.footerSection, styles.footerSectionfour)}
              >
                <h3 className={css(styles.header)}>Got a question?</h3>
                <ul className={css(styles.ul)}>
                  <li className={css(styles.li)}>
                    <a
                      className={css(styles.link)}
                      href={"mailto:help@jjdvans.com"}
                    >
                      help@jjdvans.com
                    </a>
                  </li>
                  <li className={css(styles.li)}>
                    <a
                      className={css(styles.link)}
                      href="javascript:void(Chatra('openChat', true))"
                    >
                      Chat with us
                    </a>
                  </li>
                  <li className={css(styles.li)}>
                    <a
                      className={css(styles.link)}
                      href={`${process.env.GATSBY_API_URL}job-board`}
                      target="blank"
                    >
                      Admin Login
                    </a>
                  </li>
                  <li className={css(styles.li, styles.socialLi)}>
                    <a
                      className={css(styles.socialLink)}
                      target={"_blank"}
                      href={LINKS.Facebook}
                    >
                      <img
                        alt={"JJD Facebook"}
                        className={css(styles.socialLinkImg)}
                        src={require("../../../assets/social/facebook.svg")}
                      />
                    </a>
                    <a
                      className={css(styles.socialLink)}
                      target={"_blank"}
                      href={LINKS.Youtube}
                    >
                      <img
                        alt={"JJD Youtube"}
                        className={css(styles.socialLinkImg)}
                        src={require("../../../assets/social/youtube.png")}
                      />
                    </a>
                    <a
                      className={css(styles.socialLink)}
                      target={"_blank"}
                      href={LINKS.Instagram}
                    >
                      <img
                        alt={"JJD Instagram"}
                        className={css(styles.socialLinkImg)}
                        style={{ maxWidth: 28 }}
                        src={require("../../../assets/social/instagram.svg")}
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className={css(styles.copyrightFooter)}>
          <p className={css(styles.legalBlurb)}>
            © JJD (UK) LTD 2017-{new Date().getFullYear()} (company number 09367527). All Rights Reserved.
          </p>
          <div className={css(styles.legalLinks)}>
            <Link className={css(styles.linkLegal)} to={"/terms-of-use"}>
              Terms of Use
            </Link>
            <Link className={css(styles.linkLegal)} to={"/privacy-policy"}>
              Privacy Policy
            </Link>
            <Link className={css(styles.linkLegal)} to={"/cookies-policy"}>
              Cookies Policy
            </Link>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
