import React from 'react';
import styles from './MobileMenu.styles';
import { css } from 'aphrodite';
import CloseIcon from '@material-ui/icons/CloseRounded';
import ArrowRight from '@material-ui/icons/ChevronRightRounded';
import Utils from '../../../library/utils';
import { isAndroid } from 'react-device-detect';
import { KIFFGO } from '../../../library/kiffgo';
import { SERVICES } from '../../../library/services';
import { Link } from 'gatsby';
import COLORS from '../../../styles/colors';

const MobileMenu = (props) => (
  <div className={css(styles.root, props.isOpen && styles.rootOpen)}>

    <div className={css(styles.closeContainer)}>
      <div style={{flex: 1}} />
      <a className={css(styles.closeIconContainer)} onClick={props.close}>
        <CloseIcon className={css(styles.closeIcon)}/>
      </a>
    </div>

    <div className={css(styles.content)}>
      <ul style={{paddingLeft: 0}}>
        <li className={css(styles.menuItem)} onClick={() => props.openLink('/')}>
          <div>Home</div>
          <div style={{flex: 1}} />
          <ArrowRight/>
        </li>
        <li className={css(styles.menuItem)} onClick={() => props.openLink('/book')}>
          <div>Book online</div>
          <div style={{flex: 1}} />
          <ArrowRight/>
        </li>
        <li className={css(styles.menuItem)} onClick={() => props.openLink('/about')}>
          <div>About JJD</div>
          <div style={{flex: 1}} />
          <ArrowRight/>
        </li>
        <li className={css(styles.menuItem)} onClick={() => props.openLink('/reviews')}>
          <div>Reviews</div>
          <div style={{flex: 1}} />
          <ArrowRight/>
        </li>
        <li className={css(styles.menuItem)} onClick={props.login}>
          <div>Login</div>
          <div style={{flex: 1}} />
          <ArrowRight/>
        </li>
      </ul>
    </div>

    <div className={css(styles.otherServicesBox)}>
      {SERVICES.map((service) => (
        <Link className={css(styles.serviceLink)} key={service.name} to={service.url}
              activeClassName={css(styles.serviceLinkActive)}>
          <div className={css(styles.serviceIconContainer)}>
            {service.icon({width: 24, height: 24, color: COLORS.jjdRed})}
          </div>
          <div className={css(styles.serviceName)}>
            {service.name}
          </div>
        </Link>
      ))}
    </div>

    <div className={css(styles.getInTouchContainer)}>
      <p className={css(styles.getInTouch)}>Get in touch</p>
      <a className={css(styles.phoneNumber)} href="javascript:void(Chatra('openChat', true))">Chat with us</a>
    </div>
  </div>
);

export default MobileMenu;
